import React from 'react';
import AniLink from "gatsby-plugin-transition-link/AniLink"

import Facebook from '../../assets/images/facebook.inline.js';
import Instagram from '../../assets/images/instagram.inline.js';
import Twitter from '../../assets/images/twitter.inline.js';
import Substack from '../../assets/images/substack.inline.js';
import { Icon } from '@iconify/react';


import './footer.scss';
  {/**<a href="https://news.daedalusfi.com" target="_blank" rel="noopener noreferrer"
             className="footer__social-icon" title="Substack">
               <div style={{width:"24px", height:"24px", marginTop:"-150px"}} >
            <Icon icon="simple-icons:substack" color="#b2b2b2" />
            </div>
  </a>**/}
const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        <p className="footer__copyright">
          &copy; {new Date().getFullYear()} Aristotle Finance <br/>All rights reserved.
        </p>
        {/* <p className="footer__socials">
          <a href="https://www.instagram.com/daedalus.fi/" target="_blank" rel="noopener noreferrer"
             className="footer__social-icon" title="Instagram">
            <Instagram/>
          </a>
          <a href="https://twitter.com/DaedalusHQ" target="_blank" rel="noopener noreferrer"
             className="footer__social-icon" title="Twitter">
            <Twitter/>
          </a>
        
        </p> */}
        <ul className="footer__nav">
          <li>
            <AniLink cover direction="right" to="/terms-of-use" bg="#00EBAA" title="Terms of Use">Terms of Use</AniLink>
          </li>
          <li>
            <AniLink cover direction="right" to="/privacy-policy" bg="#00EBAA" title="Privacy policy">Privacy
              policy</AniLink>
          </li>
          <li>
            <AniLink cover direction="right" to="https://careers.daedalusfi.com" bg="#00EBAA" title="Careers">Careers</AniLink>
          </li>
        </ul>
      </div>
    </footer>
  );
}

export default Footer;
